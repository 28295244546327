const ALL_COUNTRIES = [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Ägypten", value: "Ägypten" },
    { label: "Albanien", value: "Albanien" },
    { label: "Algerien", value: "Algerien" },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Antigua und Barbuda", value: "Antigua und Barbuda" },
    {
        label: "Äquatorialguinea",
        value: "Äquatorialguinea",
    },
    { label: "Argentinien", value: "Argentinien" },
    { label: "Armenien", value: "Armenien" },
    { label: "Aserbaidschan", value: "Aserbaidschan" },
    { label: "Äthiopien", value: "Äthiopien" },
    { label: "Australien", value: "Australien" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesch", value: "Bangladesch" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgien", value: "Belgien" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivien", value: "Bolivien" },
    {
        label: "Bosnien und Herzegowina",
        value: "Bosnien und Herzegowina",
    },
    { label: "Botsuana", value: "Botsuana" },
    { label: "Brasilien", value: "Brasilien" },
    { label: "Brunei Darussalam", value: "Brunei Darussalam" },
    { label: "Bulgarien", value: "Bulgarien" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cabo Verde", value: "Cabo Verde" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Cookinseln", value: "Cookinseln" },
    { label: "Costa Rica", value: "Costa Rica" },
    {
        label: "Côte d'Ivoire",
        value: "Côte d'Ivoire",
    },
    { label: "Dänemark", value: "Dänemark" },
    { label: "Deutschland", value: "Deutschland" },
    { label: "Dominica", value: "Dominica" },
    {
        label: "Dominikanische Republik",
        value: "Dominikanische Republik",
    },
    { label: "Dschibuti", value: "Dschibuti" },
    { label: "Ecuador", value: "Ecuador" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estland", value: "Estland" },
    { label: "Eswatini", value: "Eswatini" },
    { label: "Fidschi", value: "Fidschi" },
    { label: "Finnland", value: "Finnland" },
    { label: "Frankreich", value: "Frankreich" },
    { label: "Gabun", value: "Gabun" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgien", value: "Georgien" },
    { label: "Ghana", value: "Ghana" },
    { label: "Grenada", value: "Grenada" },
    { label: "Griechenland", value: "Griechenland" },
    {
        label: "Großbritannien",
        value: "Großbritannien",
    },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea-Bissau", value: "Guinea-Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    { label: "Honduras", value: "Honduras" },
    { label: "Indien", value: "Indien" },
    { label: "Indonesien", value: "Indonesien" },
    { label: "Irak", value: "Irak" },
    { label: "Iran", value: "Iran" },
    { label: "Irland", value: "Irland" },
    { label: "Island", value: "Island" },
    { label: "Israel", value: "Israel" },
    { label: "Italien", value: "Italien" },
    { label: "Jamaika", value: "Jamaika" },
    { label: "Japan", value: "Japan" },
    { label: "Jemen", value: "Jemen" },
    { label: "Jordanien", value: "Jordanien" },
    { label: "Kambodscha", value: "Kambodscha" },
    { label: "Kamerun", value: "Kamerun" },
    { label: "Kanada", value: "Kanada" },
    { label: "Kasachstan", value: "Kasachstan" },
    { label: "Katar", value: "Katar" },
    { label: "Kenia", value: "Kenia" },
    { label: "Kirgisistan", value: "Kirgisistan" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Kolumbien", value: "Kolumbien" },
    { label: "Komoren", value: "Komoren" },
    { label: "Kongo", value: "Kongo" },
    {
        label: "Kongo, Demokratische Republik",
        value: "Kongo, Demokratische Republik",
    },
    {
        label: "Korea, Demokratische Volksrepublik",
        value: "Korea, Demokratische Volksrepublik",
    },
    { label: "Korea, Republik", value: "Korea, Republik" },
    { label: "Kosovo", value: "Kosovo" },
    { label: "Kroatien", value: "Kroatien" },
    { label: "Kuba", value: "Kuba" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Laos", value: "Laos" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Lettland", value: "Lettland" },
    { label: "Libanon", value: "Libanon" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libyen", value: "Libyen" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Litauen", value: "Litauen" },
    { label: "Luxemburg", value: "Luxemburg" },
    { label: "Madagaskar", value: "Madagaskar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Malediven", value: "Malediven" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    { label: "Marokko", value: "Marokko" },
    { label: "Marshallinseln", value: "Marshallinseln" },
    { label: "Mauretanien", value: "Mauretanien" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mexiko", value: "Mexiko" },
    { label: "Mikronesien", value: "Mikronesien" },
    { label: "Moldau", value: "Moldau" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolei", value: "Mongolei" },
    { label: "Montenegro", value: "Montenegro" },
    { label: "Mosambik", value: "Mosambik" },
    { label: "Myanmar", value: "Myanmar" },
    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Neuseeland", value: "Neuseeland" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niederlande", value: "Niederlande" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "Nordmazedonien", value: "Nordmazedonien" },
    { label: "Norwegen", value: "Norwegen" },
    { label: "Oman", value: "Oman" },
    { label: "Österreich", value: "Österreich", order: 1 },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Palau", value: "Palau" },
    { label: "Panama", value: "Panama" },
    { label: "Papua-Neuguinea", value: "Papua-Neuguinea" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippinen", value: "Philippinen" },
    { label: "Polen", value: "Polen" },
    { label: "Portugal", value: "Portugal" },
    { label: "Ruanda", value: "Ruanda" },
    { label: "Rumänien", value: "Rumänien" },
    {
        label: "Russische Föderation",
        value: "Russische Föderation",
    },
    { label: "Salomonen", value: "Salomonen" },
    { label: "Sambia", value: "Sambia" },
    { label: "Samoa", value: "Samoa" },
    { label: "San Marino", value: "San Marino" },
    {
        label: "São Tomé und Príncipe",
        value: "São Tomé und Príncipe",
    },
    { label: "Saudi-Arabien", value: "Saudi-Arabien" },
    { label: "Schweden", value: "Schweden" },
    { label: "Schweiz", value: "Schweiz" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbien", value: "Serbien" },
    { label: "Seychellen", value: "Seychellen" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Simbabwe", value: "Simbabwe" },
    { label: "Singapur", value: "Singapur" },
    { label: "Slowakei", value: "Slowakei" },
    { label: "Slowenien", value: "Slowenien" },
    { label: "Somalia", value: "Somalia" },
    { label: "Spanien", value: "Spanien" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "St. Kitts und Nevis", value: "St. Kitts und Nevis" },
    { label: "St. Lucia", value: "St. Lucia" },
    {
        label: "St. Vincent und die Grenadinen",
        value: "St. Vincent und die Grenadinen",
    },
    { label: "Südafrika", value: "Südafrika" },
    { label: "Sudan", value: "Sudan" },
    { label: "Südsudan", value: "Südsudan" },
    { label: "Suriname", value: "Suriname" },
    { label: "Syrien", value: "Syrien" },
    { label: "Tadschikistan", value: "Tadschikistan" },
    { label: "Taiwan", value: "Taiwan" },
    { label: "Tansania", value: "Tansania" },
    { label: "Thailand", value: "Thailand" },
    { label: "Timor-Leste", value: "Timor-Leste" },
    { label: "Togo", value: "Togo" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinidad und Tobago", value: "Trinidad und Tobago" },
    { label: "Tschad", value: "Tschad" },
    {
        label: "Tschechische Republik",
        value: "Tschechische Republik",
    },
    { label: "Tunesien", value: "Tunesien" },
    { label: "Türkei", value: "Türkei" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    { label: "Ungarn", value: "Ungarn" },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Usbekistan", value: "Usbekistan" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Vatikanstadt", value: "Vatikanstadt" },
    { label: "Venezuela", value: "Venezuela" },
    {
        label: "Vereinigte Arabische Emirate",
        value: "Vereinigte Arabische Emirate",
    },
    { label: "Vereinigte Staaten", value: "Vereinigte Staaten" },
    { label: "Vietnam", value: "Vietnam" },
    {
        label: "Zentralafrikanische Republik",
        value: "Zentralafrikanische Republik",
    },
    { label: "Zypern", value: "Zypern" },
];

export const EU_COUNTRY_VALUES = [
    "Belgien",
    "Bulgarien",
    "Dänemark",
    "Deutschland",
    "Estland",
    "Finnland",
    "Frankreich",
    "Griechenland",
    "Irland",
    "Italien",
    "Kroatien",
    "Lettland",
    "Litauen",
    "Luxemburg",
    "Malta",
    "Niederlande",
    "Österreich",
    "Polen",
    "Portugal",
    "Rumänien",
    "Schweden",
    "Slowakei",
    "Slowenien",
    "Tschechische Republik",
    "Spanien",
    "Ungarn",
    "Zypern",
];

export const ALL_COUNTRY_VALUES = ALL_COUNTRIES.sort((a, b) => (a.order || Infinity) - (b.order || Infinity)).map(
    (c) => c.value,
);

export const COUNTRY_VALUE_AUSTRIA = "Österreich";
