import { Request } from "express";
import { Environment } from "./environment";
import { Nullable } from "./common";
import { ExistingPropertyEnumerator } from "./shared/enumeratedField";

export enum PandadocTemplateTag {
    ERSTE = "Erste",
    HYPO = "Hypo",
    OBERBANK = "Oberbank",
    RAIKA = "Raika",
    WUESTENROT = "Wuestenrot",
    SBAU = "Sbau",
    RAI_BAU = "RaiBau",
    VOLKSBANK = "Volksbank",

    NO_SIGNING_ORDER = "NoSigningOrder",

    GENERIC = "Generic",

    SINGLE_BORROWER = "Single",
    MULTIPLE_BORROWERS = "Multiple",

    PURCHASE = "Kauf",
    REFINANCE = "Umschuldung",
    CONSTRUCTION = "Bau",

    HOUSE = "Haus",
    APARTMENT = "Wohnung",

    NO_SECURITY = "NichtSimultan",
    SECURITY_APARTMENT = "SimultanWohnung",
    SECURITY_HOUSE = "SimultanHaus",

    ONE_SECTION = "OneSection",
    TWO_SECTIONS = "TwoSections",
    THREE_SECTIONS = "ThreeSections",
    FOUR_SECTIONS = "FourSections",
    FIVE_SECTIONS = "FiveSections",
    SIX_SECTIONS = "SixSections",
    SEVEN_SECTIONS = "SevenSections",
    EIGHT_SECTIONS = "EigthSections",
    NINE_SECTIONS = "NineSections",
    TEN_SECTIONS = "TenSections",
}

export const PANDADOC_SECTION_SIZE_TAGS: PandadocTemplateTag[] = [
    PandadocTemplateTag.ONE_SECTION,
    PandadocTemplateTag.TWO_SECTIONS,
    PandadocTemplateTag.THREE_SECTIONS,
    PandadocTemplateTag.FOUR_SECTIONS,
    PandadocTemplateTag.FIVE_SECTIONS,
    PandadocTemplateTag.SIX_SECTIONS,
    PandadocTemplateTag.SEVEN_SECTIONS,
    PandadocTemplateTag.EIGHT_SECTIONS,
    PandadocTemplateTag.NINE_SECTIONS,
    PandadocTemplateTag.TEN_SECTIONS,
];

export enum PandadocDocumentSectionStatus {
    UPLOADED = "document_sections_upload.UPLOADED",
    PROCESSED = "document_sections_upload.PROCESSED",
    ERROR = "document_sections_upload.ERROR",
}

export type PandadocTemplate = {
    id: string;
    name: string;
    date_created: Date;
    date_modified: Date;
    version: string;
};

export type PandadocCreator = {
    id: string;
    email: string;
    first_name: string;
    last_name: string;
    avatar: string;
    membership_id: string;
};

export type PandadocToken = {
    name: string;
    value: string;
};

export type PandadocRole = {
    id: string;
    name: string;
    signing_order: string;
    preassigned_person: string;
};

export type PandadocRoleField = PandadocRole & {
    type: string;
};

export type PandadocFieldType = "radio_buttons" | "text" | "checkbox" | "signature";

export type PandadocField = {
    uuid: string;
    name: string;
    title: string;
    placeholder: string;
    value: string;
    assigned_to: PandadocRoleField;
    field_id: string;
    type: PandadocFieldType;
    merge_field: string;
};

export type PandadocTemplateDetails = PandadocTemplate & {
    created_by: PandadocCreator;
    tokens: PandadocToken[];
    fields: PandadocField[];
    roles: PandadocRole[];
    tags: PandadocTemplateTag[];
};

export type PandadocRecipient = {
    id?: string;
    email: string;
    first_name: string;
    last_name: string;
    role: string;
    signing_order?: number;
};

export type CreatePandadocDocumentFieldValue = string | boolean | number;

export type CreatePandadocDocumentFields = {
    [key: string]: {
        value: CreatePandadocDocumentFieldValue;
    };
};

export type PandadocTrueFalse = "True" | "False";

export type CreatePandadocDocumentMetadata = {
    [key: string]: any;
    "hubspot.deal_id": string;
    syncTaskId?: string;
    specialistEmail?: string;
    shouldAutomaticallySend?: PandadocTrueFalse;
    environment?: Environment;
    documentSent?: PandadocTrueFalse;
};

export type CreatePandadocDocumentBody = {
    dealId: string;
    name: string;
    template_uuid: string;
    folder_uuid: string;
    recipients: PandadocRecipient[];
    tokens: PandadocToken[];
    fields: CreatePandadocDocumentFields;
    metadata?: CreatePandadocDocumentMetadata;
    roles?: PandadocRole[];
    tags?: string[];
    existingPropertyEnumerator?: ExistingPropertyEnumerator;
};

export type AppendPandadocDocumentSectionBody = CreatePandadocDocumentBody;

export type CreatePandadocDocumentBundleBody = {
    sections: CreatePandadocDocumentBody[];
    dealId: string;
    sendAfterBundling?: boolean;
};

export type CreatePandadocDocumentResponseLink = {
    rel: string;
    href: string;
    type: string;
};

export type CreatePandadocDocumentResult = {
    id: string;
    name: string;
    status: string;
    date_created: string;
    date_modified: string;
    expiration_date: string;
    version: string;
    uuid: string;
    links: CreatePandadocDocumentResponseLink[];
    info_message: string;
};

export type AppendPandadocDocumentSectionResult = {
    uuid: string;
    name: string;
    document_uuid: string;
    status: string;
    date_created: string;
    date_modified: string;
    info_message: string;
};

export type PandadocDocumentSectionDetailsResult = {
    uuid: string;
    name: string;
    document_uuid: string;
    status: PandadocDocumentSectionStatus;
    section_uuids: string[];
    date_created: string;
    date_modified: string;
    info_message: string;
};

export type CreatePandadocDocumentBundleResult = {
    info_message: string;
};

export type CreatePandadocDocumentBundlesResult = CreatePandadocDocumentBundleResult[];

export type PandadocWebhookRequestLinkedObject = {
    id: string;
    provider: string;
    entity_type: string;
    entity_id: string;
};

export enum PandadocWebhookRequestStatus {
    DOCUMENT_DRAFT = "document.draft",
    DOCUMENT_SENT = "document.sent",
    DOCUMENT_COMPLETED = "document.completed",
    DOCUMENT_VOIDED = "document.voided",
    DOCUMENT_VIEWED = "document.viewed",
}

export type PandadocWebhookRequestData = {
    id: string;
    name: string;
    date_created: string;
    date_modified: string;
    expiration_date: string;
    autonumbering_sequence_name: string;
    created_by: PandadocCreator;
    metadata: CreatePandadocDocumentMetadata;
    tokens: PandadocToken[];
    fields: CreatePandadocDocumentFields;
    status: PandadocWebhookRequestStatus;
    sent_by: PandadocCreator;
    grand_total: {
        amount: 0;
        currency: string;
    };
    template: {
        id: string;
        name: string;
    };
    version: string;
    linked_objects: PandadocWebhookRequestLinkedObject[];
};

export enum PandadocWebhookEventType {
    DOCUMENT_STATE_CHANGED = "document_state_changed",
    RECIPIENT_COMPLETED = "recipient_completed",
}

export type PandadocWebhookEvent = {
    event: PandadocWebhookEventType;
    data: PandadocWebhookRequestData;
};

export type PandadocWebhookRequest = PandadocWebhookEvent[];

export type SendPandadocDocumentBody = {
    subject?: string;
    message?: string;
    silent?: boolean;
    sender?: string;
};

type SendPandadocResponseRecipient = PandadocRecipient & {
    recipient_type: string;
    shared_link: string;
};

export type SendPandadocDocumentResponse = {
    id: string;
    name: string;
    status: string;
    date_created: string;
    date_modified: string;
    date_completed: string;
    expiration_date: string;
    version: string;
    uuid: string;
    recipients: SendPandadocResponseRecipient[];
};

export type PandadocDocument = {
    id: string;
    name: string;
    status: PandadocWebhookRequestStatus;
    date_created: string;
    date_modified: string;
    date_completed: string;
    expiration_date: string;
    version: string;
    uuid: string;
};

export type GetPandadocTemplatesRequest = Request & {
    query: {
        tags: string[] | Nullable;
    };
};

export type GetPandadocDocumentsResponse = {
    results: PandadocDocument[];
};

export type PandadocDocumentDetails = PandadocWebhookRequestData & {
    pricing: object;
    date_completed: string;
    tags: PandadocTemplateTag[];
    autonumbering_sequence_name_prefix: string;
    recipients: SendPandadocResponseRecipient[];
};

export type PandadocSigningOrderItem = {
    email: string;
    order: number;
};

export enum PandadocTemplateId {
    GENERIC_FINANZIERUNGSANFRAGE_NEU_1KN = "eXwkJktndLwiwGGRsnKWXC",
    GENERIC_FINANZIERUNGSANFRAGE_NEU_2KN = "3BTQsKs6pRcGQTRME543oe",

    ERSTE_VERMITTLUNGSAUFTRAG_1KN = "rMvj35Qup82DosVvwffB49",
    ERSTE_VERMITTLUNGSAUFTRAG_2KN = "eC4cZwvaYJejVFDLqx9FGa",
    ERSTE_HAUS = "hf6CLkbh5eaoY6abtTP3JJ",
    ERSTE_WOHNUNG = "HB2j22wQ2CMb8EXfpB24Xg",

    HYPO_KUNDENERKLAERUNG_1KN = "K7uc7gvjJTH7aV9TZvFEcn",
    HYPO_KUNDENERKLAERUNG_2KN = "NHvArETpG2gZJvRZtmnHTE",
    HYPO_LIEGENSCHAFT_EINFAMILIENHAUS = "QZfoKmpXv7xC9HTtKWkf8c",
    HYPO_LIEGENSCHAFT_EIGENTUMSWOHNUNG = "rsx8KpLC3jMBFEfz2x2G4b",

    RAIBAU_DATENSCHUTZERKLAERUNG_1KN = "Wm2hPMuTtz7Kz9B7fi4rFo",
    RAIBAU_DATENSCHUTZERKLAERUNG_2KN = "VcgUHb6pKjMZ86wJVUxxn3",
    RAIBAU_HAUSHALTSRECHNUNG_2KN = "oaxDpgUiGtav4VMoHgf4qc",

    RAIKA_DSGVO = "QHgSihex8z8KM83hwzJjKh",
    RAIKA_DSGVO_KN2 = "vDUoad4no6utcvRT2vmnj2",
    RAIKA_WNO_EINWILLIGUNGSERKLAERUNG_1KN = "QrH98zVULDYWkVJU8PSJzK",
    RAIKA_WNO_EINWILLIGUNGSERKLAERUNG_2KN = "2FbADYNxJ49i3mHESnkoMm",
    RAIKA_HHP_RLB_1KN = "4a3cSHyZZrV9oCE5gkRsx6",
    RAIKA_HHP_RLB_2KN = "uKQwLZuoBqKJ28LFh2cJdh",
    RAIKA_SELBSTAUSKUNFT_HIKRG_1KN = "4g2xwbuYYah5eKjyRt7nvj",
    RAIKA_SELBSTAUSKUNFT_HIKRG_2KN = "EuMWf7wX53h2wqftPum984",
    RAIKA_SELBSTAUSKUNFT_SC_1KN = "fHmJhtRupLahpVCgpgu34W",
    RAIKA_SELBSTAUSKUNFT_SC_2KN = "fb6Siwopyq47ftmSmPwpw5",
    RAIKA_FINANZIERUNGSPROTOKOLL_1KN = "5dcnd9naXeV8ZUp2cs2fJG",
    RAIKA_FINANZIERUNGSPROTOKOLL_2KN = "3PJUJA3kLGmWpwnTrJGchk",

    VOLKSBANK_WIEN_SELBSTAUSKUNFT_1KN = "QLb2QuJSXEeoQ7CyZhsTc8",
    VOLKSBANK_WIEN_SELBSTAUSKUNFT_2KN = "65sMk8CKP2dVvZCnAWieNn",
    VOLKSBANK_WIEN_IMMOBILIENBEWERTUNG = "aFRUyRauwbrsfewTwUeXvS",

    VOLKSBANK_NOE_SELBSTAUSKUNFT_1KN = "PdvEjuQQa9Amr72Mz2ffmT",
    VOLKSBANK_NOE_SELBSTAUSKUNFT_2KN = "UygQwhKdbzRghnnTyV95N8",

    WUESTENROT_FINCHECK = "DoZUvfsPr4DfAhQRFW5Uam",
    WUESTENROT_ZUSTIMMUNG_1KN = "suiTZRhMcZtNnN6fJjfF2M",
    WUESTENROT_ZUSTIMMUNG_2KN = "pDYhu66xEvvJMH6JeU9zdf",

    SBAU_LIEGENSCHAFTBESCHREIBUNG_HAUS = "voppMT8XKayeX7DjN32zEj",
    SBAU_LIEGENSCHAFTBESCHREIBUNG_WOHNUNG = "VihpjJ7hVHAucBykw2USPB",
    SBAU_KUNDENERKLAERUNG_1KN = "N9UqpWZwGMiK7NaAuPmp4D",
    SBAU_KUNDENERKLAERUNG_2KN = "nVGxxbpL7LRPgfSJzrT8R5",
}
